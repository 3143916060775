<template>
  <el-dialog
    title="提示"
    :visible.sync="$_visible"
    width="500px"
  >
    <span v-if="!isReadyHaveTask">
      个人单次只能接受一个任务，
      需要在时限要求内提交任务交付物，超时未提交则自动判定任务失败，
      无法获得奖励！
    </span>
    <span v-else>任务进行中，请先完成任务</span>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        v-if="!isReadyHaveTask"
        type="primary"
        :loading="acceptBtn"
        @click="acceptTask">
        接受任务
      </el-button>
      <el-button
        size="small"
        v-else type="primary"
        @click="closeDialog">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    isReadyHaveTask: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      acceptBtn: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    acceptTask () {
      this.acceptBtn = true
      api.acceptTask(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.$router.push('/user/task-hall')
        } else {
          this.$message.error('当前无法接受任务')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).false(() => {
        this.acceptBtn = false
        this.closeDialog()
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
