<template>
  <div class="task_detail_container" v-loading="loading">
    <!-- 详情页头部 -->
    <detail-title
      :backToPath="'/user/task-hall'"
      :descriptionList="descriptionList"
    ></detail-title>

    <!-- 详情信息列表 -->
    <task-description
      :descriptionList="descriptionList">
    </task-description>

    <!-- 接受任务按钮 -->
    <div
      class="task_submit"
      v-if="descriptionList.status === TASK_STATUS.PUBLISHED || descriptionList.status === TASK_STATUS.ONGOING">
      <el-button
        size="small"
        type="primary"
        :disabled="{
          1:false,
          3:true,
        }[descriptionList.status]
          "
        @click="dialogVisible = true">
        {{
          {
            1: '接受任务',
            3: '进行中',
          }[descriptionList.status]
        }}
      </el-button>
    </div>

    <!-- 消息提示 -->
    <accept-task
      :visible.sync="dialogVisible"
      :isReadyHaveTask="isReadyHaveTask">
    </accept-task>
  </div>
</template>

<script>
import api from '@/api/index'
import globalVar from '@/configs/globalVar'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'
import AcceptTask from '../components/accept-task-dialog.vue'

export default {
  components: {
    DetailTitle,
    TaskDescription,
    AcceptTask
  },
  data () {
    return {
      dialogVisible: false,
      isReadyHaveTask: false,
      descriptionList: {},
      loading: false,
      message: '接受任务'
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    },
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    }
  },
  created () {
    this.getDetailInfo()
    this.getCurrentTask()
  },
  methods: {
    getDetailInfo () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data ? res.data.data : {}
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    },
    // 判断当前用户是否已有任务
    getCurrentTask () {
      api.currentTask().then(res => {
        if (res.data.code === 0) {
          this.isReadyHaveTask = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
}
</style>
